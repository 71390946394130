// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mxuYNU86g2dXueJhSIr1{position:relative;height:40px;overflow:hidden;z-index:200}.HdWVeqP8VmXgL6Lr1xxH{min-height:40px;background-color:#eee;padding-bottom:10px;border-bottom:2px solid #000;position:absolute;box-sizing:border-box;bottom:0;left:0;right:0;display:flex;justify-content:center}.HdWVeqP8VmXgL6Lr1xxH>*:not(:last-child){margin-right:10px}", "",{"version":3,"sources":["webpack://./src/components/Play/OpponentHand-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,eAAA,CACA,WAAA,CAGD,sBACC,eAAA,CACA,qBAAA,CACA,mBAAA,CACA,4BAAA,CACA,iBAAA,CACA,qBAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CAEA,YAAA,CACA,sBAAA,CAGC,yCACC,iBAAA","sourcesContent":[".opponentHandWrapper {\n\t\tposition: relative;\n\t\theight: 40px;\n\t\toverflow: hidden;\n\t\tz-index: 200;\n\t}\n\n\t.opponentHand {\n\t\tmin-height: 40px;\n\t\tbackground-color: #eee;\n\t\tpadding-bottom: 10px;\n\t\tborder-bottom: 2px solid black;\n\t\tposition: absolute;\n\t\tbox-sizing: border-box;\n\t\tbottom: 0;\n\t\tleft: 0;\n\t\tright: 0;\n\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\n\t\t> * {\n\t\t\t&:not(:last-child) {\n\t\t\t\tmargin-right: 10px;\n\t\t\t}\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"opponentHandWrapper": "mxuYNU86g2dXueJhSIr1",
	"opponentHand": "HdWVeqP8VmXgL6Lr1xxH"
};
export default ___CSS_LOADER_EXPORT___;
