// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IQV1DDesHdsgsW4thZwU{font-size:48px;font-weight:700}.b_mFT75U6xrhhlMHra2v{font-size:32px;margin-bottom:24px}.EYpIxcY4CS6MBE1TsBlC{cursor:pointer;user-select:all;font-size:20px;text-decoration:underline;font-weight:700;margin-bottom:24px}.bWQP_Iu9BmZI79Wsj_8V{font-size:20px}.bWQP_Iu9BmZI79Wsj_8V a{color:#fff}.bWQP_Iu9BmZI79Wsj_8V a:hover{color:#ddd}.bWQP_Iu9BmZI79Wsj_8V a:active{color:#bbb}", "",{"version":3,"sources":["webpack://./src/components/Play/WaitingCurtain-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,kBAAA,CAGD,sBACC,cAAA,CACA,eAAA,CACA,cAAA,CACA,yBAAA,CACA,eAAA,CACA,kBAAA,CAGD,sBACC,cAAA,CACA,wBACC,UAAA,CACA,8BACC,UAAA,CAGD,+BACC,UAAA","sourcesContent":[".title {\n\t\tfont-size: 48px;\n\t\tfont-weight: 700;\n\t}\n\n\t.subtitle {\n\t\tfont-size: 32px;\n\t\tmargin-bottom: 24px;\n\t}\n\n\t.link {\n\t\tcursor: pointer;\n\t\tuser-select: all;\n\t\tfont-size: 20px;\n\t\ttext-decoration: underline;\n\t\tfont-weight: 700;\n\t\tmargin-bottom: 24px;\n\t}\n\n\t.help {\n\t\tfont-size: 20px;\n\t\ta {\n\t\t\tcolor: white;\n\t\t\t&:hover {\n\t\t\t\tcolor: #ddd;\n\t\t\t}\n\n\t\t\t&:active {\n\t\t\t\tcolor: #bbb;\n\t\t\t}\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "IQV1DDesHdsgsW4thZwU",
	"subtitle": "b_mFT75U6xrhhlMHra2v",
	"link": "EYpIxcY4CS6MBE1TsBlC",
	"help": "bWQP_Iu9BmZI79Wsj_8V"
};
export default ___CSS_LOADER_EXPORT___;
