
import * as React from "react";
import _styles from "./ShoubuValueRow-styles.module.scss";



const styles = _styles;

export const ShoubuTotal = (props: {
	total?: number
}) => {
	if (!props.total) {
		return null;
	}
	return <div className={styles.shoubuValueRow}>
		<div>合計</div>
		<div className={styles.shoubuValue}>{props.total}</div>
	</div>;
};
