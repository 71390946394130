// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L_2GkBts8zuTJPfuTt_W{box-sizing:border-box;position:relative;min-height:0;transition:min-height .3s ease-in;background-color:#d81e1e}.L_2GkBts8zuTJPfuTt_W.MxuWuwd8aIQjQRIs_F9l{min-height:20px}", "",{"version":3,"sources":["webpack://./src/components/Play/PlayerNameTag-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,iBAAA,CACA,YAAA,CAEA,iCAAA,CACA,wBAAA,CAEA,2CACC,eAAA","sourcesContent":[".nameTag {\n\t\tbox-sizing: border-box;\n\t\tposition: relative;\n\t\tmin-height: 0;\n\n\t\ttransition: min-height 0.3s ease-in;\n\t\tbackground-color: #d81e1e;\n\n\t\t&.active {\n\t\t\tmin-height: 20px;\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameTag": "L_2GkBts8zuTJPfuTt_W",
	"active": "MxuWuwd8aIQjQRIs_F9l"
};
export default ___CSS_LOADER_EXPORT___;
