// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aWoICMhBTagJur_P2IMV{text-align:center;font-size:30px}.u4vfxwsOD9tjOTdw1AAF{background-color:#d81e1e !important}", "",{"version":3,"sources":["webpack://./src/components/Play/GameActionModal/GameActionModal-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,cAAA,CAGD,sBACC,mCAAA","sourcesContent":[".caption {\n\t\ttext-align: center;\n\t\tfont-size: 30px;\n\t}\n\n\t.koikoi {\n\t\tbackground-color: #d81e1e !important;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": "aWoICMhBTagJur_P2IMV",
	"koikoi": "u4vfxwsOD9tjOTdw1AAF"
};
export default ___CSS_LOADER_EXPORT___;
