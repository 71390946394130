import * as React from "react";
import { Center } from "./Center";
import { Collection } from "./Collection";
import { OpponentHand } from "./OpponentHand";
import { PlayerHand } from "./PlayerHand";
import clsx from "clsx";
import { lovelove } from "../../rpc/proto/lovelove";

import { PlayerNameTag } from "./PlayerNameTag";
import { CardDroppedHandler, oppositePosition } from "../../utils";
import { CardMoveContext } from "../../rpc/CardMoveContext";
import { PlayerMetadataZone } from "./PlayerMetadataZone";
import { RoundEndInformation } from "../../state/IState";
import { GameActionModal, IGameModalActions } from "./GameActionModal/GameActionModal";
import { MetadataBubble } from "./MetadataBubble";
import { CenterModal, CenterModalActionPanel } from "./CenterModal";
import _styles from "./Table-styles.module.scss";


const styles = _styles;

type IGameState = lovelove.ICompleteGameState & {
	position: lovelove.PlayerPosition
}

export const Table = (props: IGameState & IGameModalActions & {
	opponentDisconnected: boolean,
	gameState: lovelove.ICompleteGameState,
	onCardDropped: CardDroppedHandler,
	roundEndView?: RoundEndInformation,
	onGameConceded: () => void,
}) => {
	const {
		opponentDisconnected,
		position,
		onCardDropped,
		onKoikoiChosen,
		onShoubuChosen,
		onContinueChosen,
		teyakuResolved,
		roundEndView,
		onGameConceded,
		gameState: {
			redPlayer,
			whitePlayer,
			deck = 0,
			deckFlipCard,
			table = [],
			active,
			oya,
			tablePlayOptions,
			shoubuOpportunity,
			month,
			monthHana,
			teyaku,
		} = {}
	} = props;

	const player = position == lovelove.PlayerPosition.Red ? redPlayer : whitePlayer;
	const opponent = position == lovelove.PlayerPosition.Red ? whitePlayer : redPlayer;

	const opponentPosition = oppositePosition(position);
	const [previewCard, setPreviewCard] = React.useState<lovelove.ICard>();
	const [concessionRequested, setConcessionRequested] = React.useState(false);
	const { move } = React.useContext(CardMoveContext);
	React.useEffect(() => {
		setPreviewCard(null);
	}, [!!move]);

	return <div className={styles.table}>
		<div className={styles.modalArea}>
			{ concessionRequested &&
				<CenterModal>
					<div>
						Are you sure you want to concede?
					</div>
					<CenterModalActionPanel>
						<div className={styles.cancelConcede} onClick={() => setConcessionRequested(false)}>いいえ</div>
						<div onClick={onGameConceded}>はい</div>
					</CenterModalActionPanel>
				</CenterModal>
			}

			<GameActionModal
				onKoikoiChosen={onKoikoiChosen}
				onShoubuChosen={onShoubuChosen}
				onContinueChosen={onContinueChosen}
				roundEndView={roundEndView}
				shoubuOpportunity={shoubuOpportunity}
				teyaku={teyaku}
				collection={player?.collection}
				opponentCollection={opponent?.collection}
				yakuInformation={player?.yakuInformation}
				opponentYakuInformation={opponent?.yakuInformation}
				hand={player?.hand?.cards}
				position={position}
				teyakuResolved={teyakuResolved}
			/>
		</div>
		<div className={styles.opponentHand}>
			<OpponentHand cards={opponent?.hand?.numberOfCards} />
		</div>
		<div className={clsx(styles.nameTag, styles.opponentNameTag)}>
			<PlayerNameTag active={opponentPosition === active}>
				<PlayerMetadataZone
					opponent
					oya={opponentPosition === oya}
					score={opponent?.score}
					koikoi={opponent?.koikoi}
					disconnected={opponentDisconnected}
				/>
				<div className={styles.concedeContainer}>
					<MetadataBubble
						onClick={() => setConcessionRequested(true)}
						className={styles.concede}
					>
						諦める
					</MetadataBubble>
				</div>
			</PlayerNameTag>
		</div>
		<div className={clsx(styles.collection, styles.opponentCollection)}>
			<div className={styles.popup}>
				<div className={styles.collectionWrapper}>
					<Collection
						cards={opponent?.collection}
						yakuInformation={opponent?.yakuInformation}
						stackUpwards
					/>
				</div>
			</div>
		</div>
		<div className={styles.center}>
			<Center
				month={month}
				monthHana={monthHana}
				cards={table}
				deck={deck}
				drawnCard={deckFlipCard}
				playOptions={tablePlayOptions}
				previewCard={previewCard}
				onPreviewCardChanged={setPreviewCard}
				onCardDropped={onCardDropped}
			/>
		</div>
		<div className={clsx(styles.collection, styles.playerCollection)}>
			<div className={styles.popup}>
				<div className={styles.collectionWrapper}>
					<Collection cards={player?.collection} yakuInformation={player?.yakuInformation} />
				</div>
			</div>
		</div>
		<div className={clsx(styles.nameTag, styles.playerNameTag)}>
			<PlayerNameTag active={position === active}>
				<PlayerMetadataZone
					oya={position === oya}
					score={player?.score}
					koikoi={player?.koikoi}
				/>
			</PlayerNameTag>
		</div>
		<div className={styles.playerArea}>
			<PlayerHand
				cards={player?.hand?.cards}
				onPreviewCardChanged={setPreviewCard}
				canPlay={tablePlayOptions?.acceptedOriginZones?.indexOf(lovelove.CardZone.Hand) >= 0}
			/>
		</div>
	</div>;
};
