/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    var $util = $protobuf.util;
    
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.lovelove = (function() {
    
        var lovelove = {};
    
        lovelove.Month = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownMonth"] = 0;
            values[valuesById[1] = "January"] = 1;
            values[valuesById[2] = "February"] = 2;
            values[valuesById[3] = "March"] = 3;
            values[valuesById[4] = "April"] = 4;
            values[valuesById[5] = "May"] = 5;
            values[valuesById[6] = "June"] = 6;
            values[valuesById[7] = "July"] = 7;
            values[valuesById[8] = "August"] = 8;
            values[valuesById[9] = "September"] = 9;
            values[valuesById[10] = "October"] = 10;
            values[valuesById[11] = "November"] = 11;
            values[valuesById[12] = "December"] = 12;
            return values;
        })();
    
        lovelove.Hana = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownSeason"] = 0;
            values[valuesById[1] = "Ayame"] = 1;
            values[valuesById[2] = "Botan"] = 2;
            values[valuesById[3] = "Fuji"] = 3;
            values[valuesById[4] = "Hagi"] = 4;
            values[valuesById[5] = "Kiku"] = 5;
            values[valuesById[6] = "Kiri"] = 6;
            values[valuesById[7] = "Matsu"] = 7;
            values[valuesById[8] = "Momiji"] = 8;
            values[valuesById[9] = "Sakura"] = 9;
            values[valuesById[10] = "Susuki"] = 10;
            values[valuesById[11] = "Ume"] = 11;
            values[valuesById[12] = "Yanagi"] = 12;
            return values;
        })();
    
        lovelove.Variation = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownVariation"] = 0;
            values[valuesById[1] = "First"] = 1;
            values[valuesById[2] = "Second"] = 2;
            values[valuesById[3] = "Third"] = 3;
            values[valuesById[4] = "Fourth"] = 4;
            return values;
        })();
    
        lovelove.GenericResponseCode = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Error"] = 0;
            values[valuesById[1] = "Ok"] = 1;
            return values;
        })();
    
        lovelove.ConnectToGameResponseCode = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ConnectToGameError"] = 0;
            values[valuesById[1] = "ConnectToGameOk"] = 1;
            values[valuesById[2] = "ConnectToGameFull"] = 2;
            values[valuesById[3] = "ConnectToGameWaiting"] = 3;
            return values;
        })();
    
        lovelove.PlayerPosition = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownPosition"] = 0;
            values[valuesById[1] = "Red"] = 1;
            values[valuesById[2] = "White"] = 2;
            return values;
        })();
    
        lovelove.CardZone = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownZone"] = 0;
            values[valuesById[1] = "Deck"] = 1;
            values[valuesById[2] = "Drawn"] = 2;
            values[valuesById[3] = "Table"] = 3;
            values[valuesById[4] = "Hand"] = 4;
            values[valuesById[5] = "Collection"] = 5;
            return values;
        })();
    
        lovelove.YakuId = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownYaku"] = 0;
            values[valuesById[1] = "Gokou"] = 1;
            values[valuesById[2] = "Shikou"] = 2;
            values[valuesById[3] = "Ameshikou"] = 3;
            values[valuesById[4] = "Sankou"] = 4;
            values[valuesById[5] = "Inoshikachou"] = 5;
            values[valuesById[6] = "Tane"] = 6;
            values[valuesById[7] = "AkatanAotanNoChoufuku"] = 7;
            values[valuesById[8] = "Akatan"] = 8;
            values[valuesById[9] = "Aotan"] = 9;
            values[valuesById[10] = "Tanzaku"] = 10;
            values[valuesById[11] = "Hanamizake"] = 11;
            values[valuesById[12] = "Tsukimizake"] = 12;
            values[valuesById[13] = "Tsukifuda"] = 13;
            values[valuesById[14] = "Kasu"] = 14;
            return values;
        })();
    
        lovelove.MessageType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Transact"] = 0;
            values[valuesById[1] = "Broadcast"] = 1;
            return values;
        })();
    
        lovelove.TeyakuId = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UnknownTeyaku"] = 0;
            values[valuesById[1] = "Teshi"] = 1;
            values[valuesById[2] = "Kuttsuki"] = 2;
            return values;
        })();
    
        lovelove.Wrapper = (function() {
    
            function Wrapper(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            Wrapper.prototype.type = 0;
            Wrapper.prototype.sequence = 0;
            Wrapper.prototype.contentType = "";
            Wrapper.prototype.data = $util.newBuffer([]);
    
            return Wrapper;
        })();
    
        lovelove.Card = (function() {
    
            function Card(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            Card.prototype.id = 0;
            Card.prototype.hana = 0;
            Card.prototype.variation = 0;
    
            return Card;
        })();
    
        lovelove.PlayOptions = (function() {
    
            function PlayOptions(p) {
                this.options = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayOptions.prototype.options = $util.emptyArray;
    
            return PlayOptions;
        })();
    
        lovelove.ZonePlayOptions = (function() {
    
            function ZonePlayOptions(p) {
                this.acceptedOriginZones = [];
                this.playOptions = {};
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ZonePlayOptions.prototype.acceptedOriginZones = $util.emptyArray;
            ZonePlayOptions.prototype.noTargetPlayOptions = null;
            ZonePlayOptions.prototype.playOptions = $util.emptyObject;
    
            return ZonePlayOptions;
        })();
    
        lovelove.YakuData = (function() {
    
            function YakuData(p) {
                this.cards = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            YakuData.prototype.id = 0;
            YakuData.prototype.value = 0;
            YakuData.prototype.cards = $util.emptyArray;
    
            return YakuData;
        })();
    
        lovelove.CardMaybe = (function() {
    
            function CardMaybe(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            CardMaybe.prototype.card = null;
    
            return CardMaybe;
        })();
    
        lovelove.ShoubuOpportunity = (function() {
    
            function ShoubuOpportunity(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ShoubuOpportunity.prototype.value = 0;
    
            return ShoubuOpportunity;
        })();
    
        lovelove.HandInformation = (function() {
    
            function HandInformation(p) {
                this.cards = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            HandInformation.prototype.numberOfCards = 0;
            HandInformation.prototype.cards = $util.emptyArray;
    
            return HandInformation;
        })();
    
        lovelove.PlayerState = (function() {
    
            function PlayerState(p) {
                this.collection = [];
                this.yakuInformation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayerState.prototype.score = 0;
            PlayerState.prototype.koikoi = false;
            PlayerState.prototype.conceded = false;
            PlayerState.prototype.hand = null;
            PlayerState.prototype.collection = $util.emptyArray;
            PlayerState.prototype.yakuInformation = $util.emptyArray;
            PlayerState.prototype.rematchRequested = false;
    
            return PlayerState;
        })();
    
        lovelove.GameEnd = (function() {
    
            function GameEnd(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            GameEnd.prototype.gameWinner = 0;
    
            return GameEnd;
        })();
    
        lovelove.CompleteGameState = (function() {
    
            function CompleteGameState(p) {
                this.table = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            CompleteGameState.prototype.month = 0;
            CompleteGameState.prototype.monthHana = 0;
            CompleteGameState.prototype.oya = 0;
            CompleteGameState.prototype.active = 0;
            CompleteGameState.prototype.deck = 0;
            CompleteGameState.prototype.deckFlipCard = null;
            CompleteGameState.prototype.table = $util.emptyArray;
            CompleteGameState.prototype.redPlayer = null;
            CompleteGameState.prototype.whitePlayer = null;
            CompleteGameState.prototype.tablePlayOptions = null;
            CompleteGameState.prototype.shoubuOpportunity = null;
            CompleteGameState.prototype.teyaku = 0;
            CompleteGameState.prototype.gameEnd = null;
    
            return CompleteGameState;
        })();
    
        lovelove.AuthenticateRequest = (function() {
    
            function AuthenticateRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            AuthenticateRequest.prototype.userId = "";
    
            return AuthenticateRequest;
        })();
    
        lovelove.AuthenticateResponse = (function() {
    
            function AuthenticateResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            return AuthenticateResponse;
        })();
    
        lovelove.ConnectToGameRequest = (function() {
    
            function ConnectToGameRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ConnectToGameRequest.prototype.roomId = "";
    
            return ConnectToGameRequest;
        })();
    
        lovelove.ConnectToGameResponse = (function() {
    
            function ConnectToGameResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ConnectToGameResponse.prototype.status = 0;
            ConnectToGameResponse.prototype.playerPosition = 0;
            ConnectToGameResponse.prototype.OpponentDisconnected = false;
    
            return ConnectToGameResponse;
        })();
    
        lovelove.NewGameUpdate = (function() {
    
            function NewGameUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            NewGameUpdate.prototype.gameState = null;
    
            return NewGameUpdate;
        })();
    
        lovelove.ResolveTeyakuRequest = (function() {
    
            function ResolveTeyakuRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            return ResolveTeyakuRequest;
        })();
    
        lovelove.ResolveTeyakuResponse = (function() {
    
            function ResolveTeyakuResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ResolveTeyakuResponse.prototype.status = 0;
    
            return ResolveTeyakuResponse;
        })();
    
        lovelove.CardId = (function() {
    
            function CardId(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            CardId.prototype.cardId = 0;
    
            return CardId;
        })();
    
        lovelove.PlayHandCardRequest = (function() {
    
            function PlayHandCardRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayHandCardRequest.prototype.handCard = null;
            PlayHandCardRequest.prototype.tableCard = null;
    
            return PlayHandCardRequest;
        })();
    
        lovelove.PlayHandCardResponse = (function() {
    
            function PlayHandCardResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayHandCardResponse.prototype.status = 0;
    
            return PlayHandCardResponse;
        })();
    
        lovelove.PlayDrawnCardRequest = (function() {
    
            function PlayDrawnCardRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayDrawnCardRequest.prototype.tableCard = null;
    
            return PlayDrawnCardRequest;
        })();
    
        lovelove.PlayDrawnCardResponse = (function() {
    
            function PlayDrawnCardResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayDrawnCardResponse.prototype.status = 0;
    
            return PlayDrawnCardResponse;
        })();
    
        lovelove.ResolveShoubuOpportunityRequest = (function() {
    
            function ResolveShoubuOpportunityRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ResolveShoubuOpportunityRequest.prototype.shoubu = false;
    
            return ResolveShoubuOpportunityRequest;
        })();
    
        lovelove.ResolveShoubuOpportunityResponse = (function() {
    
            function ResolveShoubuOpportunityResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ResolveShoubuOpportunityResponse.prototype.status = 0;
    
            return ResolveShoubuOpportunityResponse;
        })();
    
        lovelove.RequestRematchRequest = (function() {
    
            function RequestRematchRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            return RequestRematchRequest;
        })();
    
        lovelove.RequestRematchResponse = (function() {
    
            function RequestRematchResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            RequestRematchResponse.prototype.status = 0;
    
            return RequestRematchResponse;
        })();
    
        lovelove.ConcedeGameRequest = (function() {
    
            function ConcedeGameRequest(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            return ConcedeGameRequest;
        })();
    
        lovelove.ConcedeGameResponse = (function() {
    
            function ConcedeGameResponse(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ConcedeGameResponse.prototype.status = 0;
    
            return ConcedeGameResponse;
        })();
    
        lovelove.CardSlot = (function() {
    
            function CardSlot(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            CardSlot.prototype.zone = 0;
            CardSlot.prototype.index = 0;
            CardSlot.prototype.player = 0;
    
            return CardSlot;
        })();
    
        lovelove.CardMoveUpdate = (function() {
    
            function CardMoveUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            CardMoveUpdate.prototype.movedCard = null;
            CardMoveUpdate.prototype.originSlot = null;
            CardMoveUpdate.prototype.destinationSlot = null;
    
            return CardMoveUpdate;
        })();
    
        lovelove.YakuUpdatePart = (function() {
    
            function YakuUpdatePart(p) {
                this.cardIds = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            YakuUpdatePart.prototype.yakuId = 0;
            YakuUpdatePart.prototype.value = 0;
            YakuUpdatePart.prototype.cardIds = $util.emptyArray;
    
            return YakuUpdatePart;
        })();
    
        lovelove.YakuUpdate = (function() {
    
            function YakuUpdate(p) {
                this.deletedYaku = [];
                this.newOrUpdatedYaku = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            YakuUpdate.prototype.deletedYaku = $util.emptyArray;
            YakuUpdate.prototype.newOrUpdatedYaku = $util.emptyArray;
    
            return YakuUpdate;
        })();
    
        lovelove.GameStateUpdatePart = (function() {
    
            function GameStateUpdatePart(p) {
                this.cardMoveUpdates = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            GameStateUpdatePart.prototype.cardMoveUpdates = $util.emptyArray;
            GameStateUpdatePart.prototype.activePlayerUpdate = null;
            GameStateUpdatePart.prototype.playOptionsUpdate = null;
            GameStateUpdatePart.prototype.yakuUpdate = null;
            GameStateUpdatePart.prototype.opponentYakuUpdate = null;
            GameStateUpdatePart.prototype.shoubuOpportunityUpdate = null;
            GameStateUpdatePart.prototype.roundEndResult = null;
            GameStateUpdatePart.prototype.koikoiUpdate = null;
            GameStateUpdatePart.prototype.connectionStatusUpdate = null;
            GameStateUpdatePart.prototype.newGameUpdate = null;
            GameStateUpdatePart.prototype.rematchUpdate = null;
    
            return GameStateUpdatePart;
        })();
    
        lovelove.ActivePlayerUpdate = (function() {
    
            function ActivePlayerUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ActivePlayerUpdate.prototype.position = 0;
    
            return ActivePlayerUpdate;
        })();
    
        lovelove.PlayOption = (function() {
    
            function PlayOption(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayOption.prototype.targetCardId = null;
            PlayOption.prototype.originCardId = null;
    
            return PlayOption;
        })();
    
        lovelove.PlayOptionsZoneUpdate = (function() {
    
            function PlayOptionsZoneUpdate(p) {
                this.zones = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayOptionsZoneUpdate.prototype.zones = $util.emptyArray;
    
            return PlayOptionsZoneUpdate;
        })();
    
        lovelove.PlayOptionsUpdate = (function() {
    
            function PlayOptionsUpdate(p) {
                this.defunctOptions = [];
                this.newOptions = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            PlayOptionsUpdate.prototype.updatedAcceptedOriginZones = null;
            PlayOptionsUpdate.prototype.defunctOptions = $util.emptyArray;
            PlayOptionsUpdate.prototype.newOptions = $util.emptyArray;
    
            return PlayOptionsUpdate;
        })();
    
        lovelove.GameStateUpdate = (function() {
    
            function GameStateUpdate(p) {
                this.updates = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            GameStateUpdate.prototype.updates = $util.emptyArray;
    
            return GameStateUpdate;
        })();
    
        lovelove.ShoubuOpportunityUpdate = (function() {
    
            function ShoubuOpportunityUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ShoubuOpportunityUpdate.prototype.available = false;
            ShoubuOpportunityUpdate.prototype.value = 0;
    
            return ShoubuOpportunityUpdate;
        })();
    
        lovelove.KoikoiUpdate = (function() {
    
            function KoikoiUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            KoikoiUpdate.prototype.self = false;
            KoikoiUpdate.prototype.opponent = false;
    
            return KoikoiUpdate;
        })();
    
        lovelove.ConnectionStatusUpdate = (function() {
    
            function ConnectionStatusUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            ConnectionStatusUpdate.prototype.player = 0;
            ConnectionStatusUpdate.prototype.connected = false;
    
            return ConnectionStatusUpdate;
        })();
    
        lovelove.RematchUpdate = (function() {
    
            function RematchUpdate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            RematchUpdate.prototype.player = 0;
    
            return RematchUpdate;
        })();
    
        lovelove.RoundEndResultTeyakuInformation = (function() {
    
            function RoundEndResultTeyakuInformation(p) {
                this.cards = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            RoundEndResultTeyakuInformation.prototype.teyakuId = 0;
            RoundEndResultTeyakuInformation.prototype.cards = $util.emptyArray;
    
            return RoundEndResultTeyakuInformation;
        })();
    
        lovelove.RoundEndResult = (function() {
    
            function RoundEndResult(p) {
                this.teyakuInformation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            RoundEndResult.prototype.winner = 0;
            RoundEndResult.prototype.winnings = 0;
            RoundEndResult.prototype.nextRound = null;
            RoundEndResult.prototype.teyakuInformation = $util.emptyArray;
    
            return RoundEndResult;
        })();
    
        return lovelove;
    })();

    return $root;
});
