import * as React from "react";
import { ThemeContext } from "../../themes/ThemeContext";
import _styles from "./OpponentHand-styles.module.scss";



const styles = _styles;

export const OpponentHand = (props: {
	cards: number;
}) => {
	const { CardBackComponent } = React.useContext(ThemeContext).theme;
	return <div className={styles.opponentHandWrapper}>
		<div className={styles.opponentHand}>
			{[...Array(props.cards).keys()].map(index => <CardBackComponent key={index} />)}
		</div>
	</div>;
};
