// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gElbVXsxP46n4SJvmgNw{position:relative;width:100px;max-width:100%;font-weight:400;font-size:16px}.gElbVXsxP46n4SJvmgNw>img{display:block}.gElbVXsxP46n4SJvmgNw>.QutL_hbsE5g_M76_sSS9{position:absolute;writing-mode:vertical-rl;visibility:hidden;background-color:#000a;padding:4px 0}.gElbVXsxP46n4SJvmgNw:hover.YGwwUYq5S0yVxw9ftsBn>.QutL_hbsE5g_M76_sSS9{visibility:visible}.gElbVXsxP46n4SJvmgNw>.A9A9JhYWOkgDTVMqI53K{bottom:10px;left:10px}.gElbVXsxP46n4SJvmgNw>.LhPe8RGEl5m3ZERncnAe{top:10px;right:10px}", "",{"version":3,"sources":["webpack://./src/themes/mantia/CardComponent-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,WAAA,CACA,cAAA,CAMA,eAAA,CACA,cAAA,CANA,0BACC,aAAA,CAOD,4CACC,iBAAA,CACA,wBAAA,CACA,iBAAA,CACA,sBAAA,CACA,aAAA,CAGD,uEACC,kBAAA,CAGD,4CACC,WAAA,CACA,SAAA,CAGD,4CACC,QAAA,CACA,UAAA","sourcesContent":[".card {\n\t\tposition: relative;\n\n\t\twidth: 100px;\n\t\tmax-width: 100%;\n\t\t> img {\n\t\t\tdisplay: block;\n\n\t\t}\n\n\t\tfont-weight: 400;\n\t\tfont-size: 16px;\n\n\t\t> .tag {\n\t\t\tposition: absolute;\n\t\t\twriting-mode: vertical-rl;\n\t\t\tvisibility: hidden;\n\t\t\tbackground-color: #000a;\n\t\t\tpadding: 4px 0;\n\t\t}\n\n\t\t&:hover.showHints > .tag {\n\t\t\tvisibility: visible;\n\t\t}\n\n\t\t> .hana {\n\t\t\tbottom: 10px;\n\t\t\tleft: 10px;\n\t\t}\n\n\t\t> .type {\n\t\t\ttop: 10px;\n\t\t\tright: 10px;\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "gElbVXsxP46n4SJvmgNw",
	"tag": "QutL_hbsE5g_M76_sSS9",
	"showHints": "YGwwUYq5S0yVxw9ftsBn",
	"hana": "A9A9JhYWOkgDTVMqI53K",
	"type": "LhPe8RGEl5m3ZERncnAe"
};
export default ___CSS_LOADER_EXPORT___;
