import * as React from "react";
import { CardStack } from "./CardStack";
import { cardKey, CardZone } from "../../utils";
import { lovelove } from "../../rpc/proto/lovelove";

import { CardMoveContext } from "../../rpc/CardMoveContext";
import clsx from "clsx";
import _styles from "./PlayerHand-styles.module.scss";


const styles = _styles;

export const PlayerHand = (props: {
	cards: lovelove.ICard[];
	canPlay: boolean;
	onPreviewCardChanged?: (card: lovelove.ICard) => void;
}) => {
	const onNoCardSelected = React.useCallback(() => props.onPreviewCardChanged?.(null), [props.onPreviewCardChanged]);
	const { move } = React.useContext(CardMoveContext);
	const moveOrigin = move?.from?.zone === CardZone.Hand ? move.from : null;
	const cards = [null, ...(props.cards ?? [])];
	return <div className={clsx(styles.playerHand, props.canPlay && styles.active)} onMouseLeave={onNoCardSelected}>
		{cards.map((card, index) => <div className={clsx(props.canPlay && styles.handCard)} key={cardKey(card, index)}>
			<CardStack
				cards={[(moveOrigin?.index === index - 1) ? null : card]}
				onCardSelected={props.onPreviewCardChanged}
				canDrag={props.canPlay}
				zone={CardZone.Hand}
				index={index-1}
			/>
		</div>)}
	</div>;
};
