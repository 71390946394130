import * as React from "react";
import { CardStack } from "./CardStack";
import { CardDroppedHandler, cardKey, CardLocation, CardMove, CardWithOffset, CardZone, Vector2 } from "../../utils";
import { lovelove } from "../../rpc/proto/lovelove";

import { CardMoveContext } from "../../rpc/CardMoveContext";
import { DragDropTypes } from "./DragDropTypes";
import { useDrop } from "react-dnd";
import clsx from "clsx";
import { Deck } from "./Deck";
import _styles from "./Center-styles.module.scss";


const styles = _styles;

const CenterCardStack = (props: {
	card: lovelove.ICard;
	index: number;
	move: CardMove;
	playOptions: lovelove.IZonePlayOptions;
	previewCard: lovelove.ICard;
	onCardDropped?: CardDroppedHandler;
}) => {
	const cards = [props.card] as CardWithOffset[];

	if (props.move) {
		cards.push({
			...props.move.from.card,
			offset: props.move.offset
		});
	}

	return <CardStack
		cards={cards}
		playOptions={props.playOptions?.playOptions?.[props.card?.id]?.options ?? []}
		onCardDropped={props.onCardDropped}
		previewCard={props.previewCard}
		zone={CardZone.Table}
		index={props.index}
		stunted
		laminated
	/>;
};

export const Center = (props: {
	deck: number;
	drawnCard?: lovelove.ICard;
	cards: lovelove.ICardMaybe[];
	monthHana: lovelove.Hana,
	month: lovelove.Month,
	playOptions: lovelove.IZonePlayOptions;
	onPreviewCardChanged?: (card: lovelove.ICard) => void;
	previewCard: lovelove.ICard;
	onCardDropped?: CardDroppedHandler;
}) => {
	const { move } = React.useContext(CardMoveContext);
	const moveDestination = move?.to.zone === CardZone.Table ? move.to : null;

	const [{canDrop, isOver}, drop] = useDrop(() => ({
		accept: DragDropTypes.Card,
		canDrop: (item: { card: CardLocation, offset: Vector2 }) => props.playOptions?.noTargetPlayOptions?.options?.indexOf(item.card.card.id ?? 0) >= 0,
		drop: (item) => {
			// const rect = wrapperRef.current.getBoundingClientRect();
			props.onCardDropped?.(
				{
					from: item.card,
					to: {
						zone: CardZone.Table,
						card: null,
					},
					offset: {
						x: 0, //item.offset.x - rect.left - cardStackSpacing.horizontal,
						y: 0, //item.offset.y - rect.top - cardStackSpacing.vertical
					}
				}
			);
		},
		collect: (monitor) => ({
			canDrop: monitor.canDrop(),
			isOver: monitor.isOver(),
		})
	}), [props.playOptions, props.onCardDropped]);

	return <div className={styles.center}>
		<div className={clsx(styles.deck, props.drawnCard && styles.active)}>
			<div className={styles.deckStack}>
				<Deck month={props.month} monthHana={props.monthHana} cards={props.deck} />
			</div>
			<CardStack
				cards={[props.drawnCard]}
				zone={CardZone.Drawn}
				onCardSelected={props.onPreviewCardChanged}
				canDrag={props.playOptions?.acceptedOriginZones?.indexOf(lovelove.CardZone.Drawn) >= 0}
				onMouseLeave={() => props.onPreviewCardChanged(null)}
			/>
		</div>
		<div className={clsx(styles.cards, canDrop && isOver && styles.dropPossible)} ref={drop}>
			<div className={styles.cardRow}>
				{props.cards
					.filter((_, index) => index % 2 === 0)
					.map((card, index) => {
						const tableIndex = (index * 2);
						return <CenterCardStack
							key={cardKey(card?.card, `center_top_${index}`)}
							card={card?.card}
							index={tableIndex}
							move={tableIndex === moveDestination?.index ? move : null}
							{...props}
						/>;
					})}
			</div>
			<div className={styles.cardRow}>
				{props.cards
					.filter((_, index) => index % 2 === 1)
					.map((card, index) => {
						const tableIndex = (index * 2 + 1);
						return <CenterCardStack
							key={cardKey(card?.card, `center_bottom_${index}`)}
							card={card?.card}
							index={tableIndex}
							move={tableIndex === moveDestination?.index ? move : null}
							{...props}
						/>;
					})}
			</div>
		</div>
	</div>;
};
