
import * as React from "react";
import _styles from "./CenterModal-styles.module.scss";


const styles = _styles;

export const CenterModal: React.FC = ({children}) => {
	return <div className={styles.centerModalContainer}>
		<div className={styles.centerModal}>{children}</div>
	</div>;
};


export const CenterModalActionPanel: React.FC = ({children}) => {
	return <div className={styles.actionButtons}>
		{children}
	</div>;
};
