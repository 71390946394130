// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coshQfNEDd73RgTPmUYh{width:100px;background-color:#222}.coshQfNEDd73RgTPmUYh:before{content:\"\";display:block;width:100%;padding-top:159.933%;border:2px solid #000;box-sizing:border-box}", "",{"version":3,"sources":["webpack://./src/themes/mantia/CardBackComponent-styles.module.scss"],"names":[],"mappings":"AAAA,sBAUE,WAAA,CACA,qBAAA,CAVA,6BACC,UAAA,CACA,aAAA,CACA,UAAA,CACA,oBAAA,CACA,qBAAA,CACA,qBAAA","sourcesContent":[".cardBack {\n\t\t&:before {\n\t\t\tcontent: \"\";\n\t\t\tdisplay: block;\n\t\t\twidth: 100%;\n\t\t\tpadding-top: 159.933%;\n\t\t\tborder: 2px solid black;\n\t\t\tbox-sizing: border-box;\n\t\t}\n\n\t\twidth: 100px;\n\t\tbackground-color: #222;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardBack": "coshQfNEDd73RgTPmUYh"
};
export default ___CSS_LOADER_EXPORT___;
