import * as React from "react";

import clsx from "clsx";
import _styles from "./PlayerNameTag-styles.module.scss";


const styles = _styles;

export const PlayerNameTag: React.FC<{active?: boolean}> = (props) => {
	return <div className={clsx(styles.nameTag, props.active && styles.active)}>
		{props.children}
	</div>;
};