// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3yVj7wygULQbbWVX1Ia{margin-top:40px;display:flex}._3yVj7wygULQbbWVX1Ia .Y5UGizRBT7lPOytQ8_5c{text-align:right;flex:1}", "",{"version":3,"sources":["webpack://./src/components/Play/GameActionModal/ShoubuValueRow-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,4CACC,gBAAA,CACA,MAAA","sourcesContent":[".shoubuValueRow {\n\t\tmargin-top: 40px;\n\t\tdisplay: flex;\n\t\t.shoubuValue {\n\t\t\ttext-align: right;\n\t\t\tflex: 1;\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shoubuValueRow": "_3yVj7wygULQbbWVX1Ia",
	"shoubuValue": "Y5UGizRBT7lPOytQ8_5c"
};
export default ___CSS_LOADER_EXPORT___;
