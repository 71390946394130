
import clsx from "clsx";
import * as React from "react";
import _styles from "./MetadataBubble-styles.module.scss";


const styles = _styles;

export const MetadataBubble: React.FC<{
	className?: string;
	onClick?: () => void
}> = (props) => {
	return <div
		className={clsx(styles.metadataBubble, props.className)}
		onClick={props.onClick}
	>
		{props.children}
	</div>;
};
