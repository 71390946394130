// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PSlMjtDY_WaMV6m2CQjA{position:absolute;display:flex;justify-content:flex-start;bottom:100%;left:0;padding:10px}.PSlMjtDY_WaMV6m2CQjA.ED6dEh__ZtfPB_S_tFTS{bottom:auto;top:100%}.PSlMjtDY_WaMV6m2CQjA>*:not(:first-child){margin-left:10px}.V3Al6c8Vo95X24TU8GuX{background-color:#6060bf}.sH4VhXsL0199gFtpsIew{background-color:#d81e1e}.yyjcoC7adj4eqQDuoaTq{background-color:#ff8db6}", "",{"version":3,"sources":["webpack://./src/components/Play/PlayerMetadataZone-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,0BAAA,CAEA,WAAA,CACA,MAAA,CACA,YAAA,CACA,2CACC,WAAA,CACA,QAAA,CAGD,0CACC,gBAAA,CAIF,sBACC,wBAAA,CAGD,sBACC,wBAAA,CAGD,sBACC,wBAAA","sourcesContent":[".metadataZone {\n\t\tposition: absolute;\n\t\tdisplay: flex;\n\t\tjustify-content: flex-start;\n\n\t\tbottom: 100%;\n\t\tleft: 0;\n\t\tpadding: 10px;\n\t\t&.opponent {\n\t\t\tbottom: auto;\n\t\t\ttop: 100%;\n\t\t}\n\n\t\t> *:not(:first-child) {\n\t\t\tmargin-left: 10px;\n\t\t}\n\t}\n\n\t.oya {\n\t\tbackground-color: #6060bf;\n\t}\n\n\t.koikoi {\n\t\tbackground-color: #d81e1e;\n\t}\n\n\t.disconnected {\n\t\tbackground-color: #ff8db6;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metadataZone": "PSlMjtDY_WaMV6m2CQjA",
	"opponent": "ED6dEh__ZtfPB_S_tFTS",
	"oya": "V3Al6c8Vo95X24TU8GuX",
	"koikoi": "sH4VhXsL0199gFtpsIew",
	"disconnected": "yyjcoC7adj4eqQDuoaTq"
};
export default ___CSS_LOADER_EXPORT___;
