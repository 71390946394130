import * as React from "react";
import { CardType, getCardType, getSeasonMonth, getYakuName } from "../../utils";
import { CardStack } from "./CardStack";
import { lovelove } from "../../rpc/proto/lovelove";

import clsx from "clsx";
import _styles from "./Collection-styles.module.scss";


const styles = _styles;

export const Collection = (props: {
	cards?: lovelove.ICard[];
	stackUpwards?: boolean;
	yakuInformation?: lovelove.IYakuData[]
}) => {
	const [previewYakuId, setPreviewYakuId] = React.useState<number>(null);
	const [selectedYakuId, setSelectedYakuId] = React.useState<number>(null);
	const yakuId = previewYakuId ?? selectedYakuId;
	const yakuCards = props.yakuInformation?.find(yaku => yaku.id === yakuId)?.cards;
	const groups = React.useMemo(
		() => {
			if (!props.cards) {
				return [];
			}

			const groups = Object.values(props.cards.reduce(
				(total, next) => {
					const type = getCardType(next);
					total[type] ??= {
						type,
						cards: [],
					};
					total[type].cards.push(next);
					return total;
				},
				{} as Record<number, {
					type: CardType,
					cards: lovelove.ICard[]
				}>
			));

			for (const group of groups) {
				group.cards.sort((a, b) => getSeasonMonth(a.hana) - getSeasonMonth(b.hana));
			}
			return groups;
		},
		[props.cards]
	);

	return <div className={styles.collection}>
		<div className={styles.yakuSelectorContainer}>
			<div className={clsx(styles.yakuSelector, props.stackUpwards && styles.stackUpwards)}>
				{(props.yakuInformation ?? []).map(yaku =>
					<div
						key={yaku.id}
						className={clsx(styles.yaku, selectedYakuId === yaku.id && styles.yakuSelected)}
						onMouseOver={() => setPreviewYakuId(yaku.id)}
						onMouseOut={() => setPreviewYakuId(null)}
						onClick={() => {
							if (selectedYakuId == yaku.id) {
								setSelectedYakuId(null);
								return;
							}
							setSelectedYakuId(yaku.id);
						}}>
						<div className={styles.yakuValue}>{yaku.value}</div>
						<div className={styles.yakuName}>{getYakuName(yaku.id)}</div>
					</div>
				)}
			</div>
		</div>
		{groups.map(group => <CardStack
			key={group.type}
			cards={group.cards}
			stackDepth={5}
			stackUpwards={props.stackUpwards}
			highlightedCardsIds={yakuCards}
		/>)}
	</div>;
};
