// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VB7hG6hBMi1Keukohtw6{z-index:999;pointer-events:auto;position:fixed;top:10px;right:10px;display:flex}.VB7hG6hBMi1Keukohtw6>a{font-size:16px;line-height:16px;font-weight:700;cursor:pointer;color:#000}.VB7hG6hBMi1Keukohtw6>a:hover{text-decoration:underline;color:#222}.VB7hG6hBMi1Keukohtw6>a:active{text-decoration:underline;color:#333}", "",{"version":3,"sources":["webpack://./src/components/InfoLinksWrapper-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,mBAAA,CACA,cAAA,CACA,QAAA,CACA,UAAA,CACA,YAAA,CACA,wBACC,cAAA,CACA,gBAAA,CACA,eAAA,CACA,cAAA,CAEA,UAAA,CAEA,8BACC,yBAAA,CACA,UAAA,CAGD,+BACC,yBAAA,CACA,UAAA","sourcesContent":[".links {\n\t\tz-index: 999;\n\t\tpointer-events: auto;\n\t\tposition: fixed;\n\t\ttop: 10px;\n\t\tright: 10px;\n\t\tdisplay: flex;\n\t\t> a {\n\t\t\tfont-size: 16px;\n\t\t\tline-height: 16px;\n\t\t\tfont-weight: 700;\n\t\t\tcursor: pointer;\n\n\t\t\tcolor: black;\n\n\t\t\t&:hover {\n\t\t\t\ttext-decoration: underline;\n\t\t\t\tcolor: #222;\n\t\t\t}\n\n\t\t\t&:active {\n\t\t\t\ttext-decoration: underline;\n\t\t\t\tcolor: #333;\n\t\t\t}\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": "VB7hG6hBMi1Keukohtw6"
};
export default ___CSS_LOADER_EXPORT___;
