// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Yv6nk_l39Nw75zKpgvTd{0%{background:#222}50%{background:#4a4a4a}}.HqQj_qme3vhxh8GERGa8{background-color:#222;border-top:2px solid #000;padding-top:10px;padding-bottom:10px;margin-left:-110px;display:flex;justify-content:center}.HqQj_qme3vhxh8GERGa8.gKhopscp_wm6LMAwYus3{animation-name:Yv6nk_l39Nw75zKpgvTd;animation-duration:3s;animation-iteration-count:infinite}.HqQj_qme3vhxh8GERGa8>*:not(:last-child){margin-right:10px}.HqQj_qme3vhxh8GERGa8 .Xh74F0omLtxppqRJoPFw{transition:margin 150ms ease-in-out}.HqQj_qme3vhxh8GERGa8 .Xh74F0omLtxppqRJoPFw:hover{margin-top:-20px}", "",{"version":3,"sources":["webpack://./src/components/Play/PlayerHand-styles.module.scss"],"names":[],"mappings":"AAAA,gCACE,GACC,eAAA,CAED,IACC,kBAAA,CAAA,CAIF,sBACC,qBAAA,CACA,yBAAA,CAQA,gBAAA,CACA,mBAAA,CAEA,kBAAA,CAEA,YAAA,CACA,sBAAA,CAZA,2CACC,mCAAA,CACA,qBAAA,CACA,kCAAA,CAYA,yCACC,iBAAA,CAIF,4CACC,mCAAA,CACA,kDACC,gBAAA","sourcesContent":["@keyframes handPulseAnimation {\n\t\t0% {\n\t\t\tbackground: #222;\n\t\t}\n\t\t50% {\n\t\t\tbackground: #4a4a4a;\n\t\t}\n\t}\n\n\t.playerHand {\n\t\tbackground-color: #222;\n\t\tborder-top: 2px solid black;\n\n\t\t&.active {\n\t\t\tanimation-name: handPulseAnimation;\n\t\t\tanimation-duration: 3s;\n\t\t\tanimation-iteration-count: infinite;\n\t\t}\n\n\t\tpadding-top: 10px;\n\t\tpadding-bottom: 10px;\n\n\t\tmargin-left: -110px;\n\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\n\t\t> * {\n\t\t\t&:not(:last-child) {\n\t\t\t\tmargin-right: 10px;\n\t\t\t}\n\t\t}\n\n\t\t.handCard {\n\t\t\ttransition: margin 150ms ease-in-out;\n\t\t\t&:hover {\n\t\t\t\tmargin-top: -20px;\n\t\t\t}\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerHand": "HqQj_qme3vhxh8GERGa8",
	"active": "gKhopscp_wm6LMAwYus3",
	"handPulseAnimation": "Yv6nk_l39Nw75zKpgvTd",
	"handCard": "Xh74F0omLtxppqRJoPFw"
};
export default ___CSS_LOADER_EXPORT___;
