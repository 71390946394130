
import clsx from "clsx";
import * as React from "react";
import { lovelove } from "../../rpc/proto/lovelove";
import { FullScreenCurtain as FullScreenCurtain } from "./FullScreenCurtain";
import { MetadataBubble } from "./MetadataBubble";
import _styles from "./EndGameCurtain-styles.module.scss";


const styles = _styles;

export const EndGameCurtain = (props: {
	position: lovelove.PlayerPosition;
	gameState: lovelove.ICompleteGameState;
	onRematchRequested: () => void;
}) => {
	console.log("redraw curtain");
	const player = props.position === lovelove.PlayerPosition.Red
		? props.gameState.redPlayer
		: props.gameState.whitePlayer;
	const opponent = props.position === lovelove.PlayerPosition.Red
		? props.gameState.whitePlayer
		: props.gameState.redPlayer;
	return <FullScreenCurtain>
		<div className={styles.endGameCurtainContent}>
			<div className={styles.playerArea}>
				{opponent.conceded &&
					<div className={styles.concedeContainer}>
						<MetadataBubble className={styles.concede}>
							諦めた
						</MetadataBubble>
					</div>
				}
				<MetadataBubble className={styles.score}>
					{opponent.score}
				</MetadataBubble>
				{opponent.rematchRequested &&
					<div className={styles.rematchContainer}>
						<MetadataBubble className={styles.rematch}>
							もう一回
						</MetadataBubble>
					</div>
				}
			</div>
			<div className={styles.status}>
				{props.gameState.gameEnd.gameWinner === lovelove.PlayerPosition.UnknownPosition
					? "引き分け"
					: props.position === props.gameState.gameEnd.gameWinner
						? "勝利"
						: "負け"}
			</div>
			<div className={styles.playerArea}>
				{player.conceded &&
					<div className={styles.concedeContainer}>
						<MetadataBubble className={styles.concede}>
							諦めた
						</MetadataBubble>
					</div>
				}
				<MetadataBubble className={styles.score}>
					{player.score}
				</MetadataBubble>
				<div className={styles.rematchContainer}>
					<MetadataBubble
						className={clsx(styles.rematch, !player.rematchRequested && styles.action)}
						onClick={() => {
							if (!player.rematchRequested) {
								props.onRematchRequested();
							}
						}}
					>
						<div className={styles.rematchBorder}> </div>
						もう一回
					</MetadataBubble>
				</div>
			</div>
		</div>
	</FullScreenCurtain>;
};

