// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XPIN69oXzcrj9xGZAuVp{width:100px}.XPIN69oXzcrj9xGZAuVp:before{content:\"\";display:block;width:100%;padding-top:163.933%}", "",{"version":3,"sources":["webpack://./src/themes/mantia/CardPlaceholderComponent-styles.module.scss"],"names":[],"mappings":"AAAA,sBAQE,WAAA,CAPA,6BACC,UAAA,CACA,aAAA,CACA,UAAA,CACA,oBAAA","sourcesContent":[".cardPlaceholder {\n\t\t&:before {\n\t\t\tcontent: \"\";\n\t\t\tdisplay: block;\n\t\t\twidth: 100%;\n\t\t\tpadding-top: 163.933%;\n\t\t}\n\n\t\twidth: 100px;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardPlaceholder": "XPIN69oXzcrj9xGZAuVp"
};
export default ___CSS_LOADER_EXPORT___;
