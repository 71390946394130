import * as React from "react";

import { useContext } from "react";
import { lovelove } from "../../../rpc/proto/lovelove";
import { ThemeContext } from "../../../themes/ThemeContext";
import _styles from "./ShoubuYakuDisplay-styles.module.scss";


const styles = _styles;

export interface IYakuSummary {
	id: string;
	name: string;
	value: number;
	cards: lovelove.ICard[];
}

export const ShoubuYakuDisplay = (props: {
	yakuInformation: IYakuSummary[]
}) => {
	const context = useContext(ThemeContext);
	const CardComponent = context.theme.CardComponent;

	if (!props.yakuInformation) {
		return null;
	}

	return <>
		{props.yakuInformation.map(yaku => <div key={yaku.id} className={styles.yaku}>
			<div className={styles.yakuName}>{yaku.name}</div>
			<div className={styles.yakuValue}>{yaku.value}</div>
			<div className={styles.yakuCards}>
				{yaku.cards.map(card =>
					<div key={card.id} className={styles.yakuCard}>
						<CardComponent hideHints {...card} />
					</div>
				)}
			</div>
		</div>)}
	</>;
};
