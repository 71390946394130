
import clsx from "clsx";
import * as React from "react";
import _styles from "./FullScreenCurtain-styles.module.scss";


const styles = _styles;

export const FullScreenCurtain: React.FC<{centerOnly?: boolean}> = ({children, centerOnly}) => {
	return <div className={clsx(styles.waitingCurtain, centerOnly && styles.collapsed)}>
		<div className={styles.top} />
		<div className={styles.core}>
			<div className={styles.content}>
				{children}
			</div>
		</div>
		<div className={styles.bottom} />
	</div>;
};
