
import clsx from "clsx";
import * as React from "react";
import { MetadataBubble } from "./MetadataBubble";
import _styles from "./PlayerMetadataZone-styles.module.scss";


const styles = _styles;

export const PlayerMetadataZone = (props: {
	opponent?: boolean,
	score: number,
	oya?: boolean,
	koikoi?: boolean,
	disconnected?: boolean,
}) => {
	return <div className={clsx(styles.metadataZone, props.opponent && styles.opponent)}>
		<MetadataBubble>{props.score ?? 0}</MetadataBubble>
		{props.oya && <MetadataBubble className={styles.oya}>親</MetadataBubble>}
		{props.koikoi && <MetadataBubble className={styles.koikoi}>こいこい</MetadataBubble>}
		{props.disconnected && <MetadataBubble className={styles.disconnected}>接続遮断</MetadataBubble>}
	</div>;
};
