// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/Kaisei_Tokumin/KaiseiTokumin-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/Kaisei_Tokumin/KaiseiTokumin-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/Kaisei_Tokumin/KaiseiTokumin-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/assets/Kaisei_Tokumin/KaiseiTokumin-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Kaisei-Tokumin;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Kaisei-Tokumin;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:500}@font-face{font-family:Kaisei-Tokumin;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:700}@font-face{font-family:Kaisei-Tokumin;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:800}html{font-family:Kaisei-Tokumin;max-width:100%}body{max-width:100%;margin:0;background-color:#ddd;color:#fff}", "",{"version":3,"sources":["webpack://./src/global.sass"],"names":[],"mappings":"AAAA,WACC,0BAAA,CACA,2CAAA,CAED,WACC,0BAAA,CACA,2CAAA,CACA,eAAA,CAED,WACC,0BAAA,CACA,2CAAA,CACA,eAAA,CAED,WACC,0BAAA,CACA,2CAAA,CACA,eAAA,CAED,KACC,0BAAA,CACA,cAAA,CAED,KACC,cAAA,CACA,QAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":["@font-face\r\n\tfont-family: Kaisei-Tokumin\r\n\tsrc: url(\"/assets/Kaisei_Tokumin/KaiseiTokumin-Regular.ttf\")\r\n\r\n@font-face\r\n\tfont-family: Kaisei-Tokumin\r\n\tsrc: url(\"/assets/Kaisei_Tokumin/KaiseiTokumin-Medium.ttf\")\r\n\tfont-weight: 500\r\n\r\n@font-face\r\n\tfont-family: Kaisei-Tokumin\r\n\tsrc: url(\"/assets/Kaisei_Tokumin/KaiseiTokumin-Bold.ttf\")\r\n\tfont-weight: 700\r\n\r\n@font-face\r\n\tfont-family: Kaisei-Tokumin\r\n\tsrc: url(\"/assets/Kaisei_Tokumin/KaiseiTokumin-ExtraBold.ttf\")\r\n\tfont-weight: 800\r\n\r\nhtml\r\n\tfont-family: Kaisei-Tokumin\r\n\tmax-width: 100%\r\n\r\nbody\r\n\tmax-width: 100%\r\n\tmargin: 0\r\n\tbackground-color: #ddd\r\n\tcolor: white\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
