
import * as React from "react";
import { Link } from "react-router-dom";
import { FullScreenCurtain } from "./FullScreenCurtain";
import _styles from "./WaitingCurtain-styles.module.scss";


const styles = _styles;

export const WaitingCurtain = (props: {
	roomFull?: boolean,
	connected?: boolean,
}) => {
	return <FullScreenCurtain centerOnly={!props.connected}>
		<div className={styles.title}>hanafuda.live</div>
		<div className={styles.subtitle}>An online hanafuda game.</div>

		{ props.connected
			? props.roomFull
				? <div className={styles.help}>This room is already in use, please <Link to="/">click here</Link> to generate a new room.</div>
				: <>
					<div className={styles.help}>Send this link to a friend to play:</div>
					<div
						className={styles.link}
						onClick={() => navigator.clipboard.writeText(window.location.href)}
					>
						{window.location.href}
					</div>
				</>
			: <div className={styles.subtitle}>Connecting...</div>
		}
		<div className={styles.help}>
			An explanation of the rules can be <a href="https://fudawiki.org/en/hanafuda/games/koi-koi">found here</a>.
		</div>
	</FullScreenCurtain>;
};
