import * as React from "react";
import _styles from "./InfoLinksWrapper-styles.module.scss";



const styles = _styles;

export const InfoLinksWrapper: React.FC = ({children}) => {
	return <>
		<div className={styles.links}>
			<a href="https://github.com/riichinomics/lovelove">More Info</a>
		</div>
		{children}
	</>;
};
