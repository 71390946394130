// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pnguzStxl0hP83pKqfwj{border-radius:3px;background-color:#333;min-width:49px;box-sizing:border-box;text-align:center;font-weight:800;padding:6px 12px 8px;font-size:24px}", "",{"version":3,"sources":["webpack://./src/components/Play/MetadataBubble-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,cAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA,CACA,oBAAA,CACA,cAAA","sourcesContent":[".metadataBubble {\n\t\tborder-radius: 3px;\n\t\tbackground-color: #333;\n\t\tmin-width: 49px;\n\t\tbox-sizing: border-box;\n\t\ttext-align: center;\n\t\tfont-weight: 800;\n\t\tpadding: 6px 12px 8px;\n\t\tfont-size: 24px;\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metadataBubble": "pnguzStxl0hP83pKqfwj"
};
export default ___CSS_LOADER_EXPORT___;
