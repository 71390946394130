// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ao68ld0XHCodHbDNq13W{display:flex;align-items:center}.Ao68ld0XHCodHbDNq13W:not(:first-child){margin-top:30px}.Ao68ld0XHCodHbDNq13W .tWnOjRhgUPx6OC7cPVie{font-weight:bold}.Ao68ld0XHCodHbDNq13W .O0hZgD6cOuI2s1T_FahY{margin-left:10px}.Ao68ld0XHCodHbDNq13W .sZ9ushoGkFFSDxhh7gfA{margin-left:60px;flex:1;justify-content:flex-end;display:flex}.Ao68ld0XHCodHbDNq13W .sZ9ushoGkFFSDxhh7gfA .uZn1Q53x9gAKGF10qGT3{margin-left:10px;width:50px}", "",{"version":3,"sources":["webpack://./src/components/Play/GameActionModal/ShoubuYakuDisplay-styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAEA,wCACC,eAAA,CAGD,4CACC,gBAAA,CAGD,4CACC,gBAAA,CAGD,4CACC,gBAAA,CACA,MAAA,CACA,wBAAA,CACA,YAAA,CACA,kEACC,gBAAA,CACA,UAAA","sourcesContent":[".yaku {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t&:not(:first-child) {\n\t\t\tmargin-top: 30px;\n\t\t}\n\n\t\t.yakuName {\n\t\t\tfont-weight: bold;\n\t\t}\n\n\t\t.yakuValue {\n\t\t\tmargin-left: 10px;\n\t\t}\n\n\t\t.yakuCards {\n\t\t\tmargin-left: 60px;\n\t\t\tflex: 1;\n\t\t\tjustify-content: flex-end;\n\t\t\tdisplay: flex;\n\t\t\t.yakuCard {\n\t\t\t\tmargin-left: 10px;\n\t\t\t\twidth: 50px;\n\t\t\t}\n\t\t}\n\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yaku": "Ao68ld0XHCodHbDNq13W",
	"yakuName": "tWnOjRhgUPx6OC7cPVie",
	"yakuValue": "O0hZgD6cOuI2s1T_FahY",
	"yakuCards": "sZ9ushoGkFFSDxhh7gfA",
	"yakuCard": "uZn1Q53x9gAKGF10qGT3"
};
export default ___CSS_LOADER_EXPORT___;
